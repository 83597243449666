<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                작성글
              </h4>
              <p class="card-title-desc">현재 작성된 공지사항을 확인합니다.</p>
              <div class="mb-3">
                <router-link to="/notice/add">
                  <b-button variant="primary" class="float-right">등록</b-button>
                </router-link>
              </div>
              <div class=" mg-b-10 fr">
                <form class="d-flex" @submit.prevent="getPostListSearch">
                  <select v-model="courseId" class="form-control mg-r-5">
                    <option value="">코스선택</option>
                    <option v-for="item in courseList" :key="item.idx" :value="item.idx" selected>
                      {{ item.name }}
                    </option>
                  </select>
                  <b-form-input
                    id="inline-form-input-name"
                    v-model="searchKeyword"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="제목"
                  ></b-form-input>
                  <button class="btn btn-primary btn-sm" type="submit">
                    검색
                  </button>
                </form>
              </div>
              <div class="table-responsive mb-0">
                <table class="table table-hover">
                  <colgroup></colgroup>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>코스</th>
                      <th>제목</th>
                      <th>유형</th>
                      <th>정렬</th>
                      <th>공개여부</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in list" :key="item.idx">
                      <td>{{ pageData.pageStartIndex - i }}</td>
                      <td>{{ item.courseName ? item.courseName : '-' }}</td>
                      <td>{{ item.title }}</td>
                      <td>
                        <span v-if="item.type === 'movie'" class="badge bg-primary">동영상</span>
                        <span v-if="item.type === 'movieNote'" class="badge bg-danger"
                          >동영상+노트</span
                        >
                        <span v-if="item.type === 'movieQuiz'" class="badge bg-danger"
                          >동영상+코드</span
                        >
                        <span v-if="item.type === 'quiz'" class="badge bg-info">노트 + 코드</span>
                        <span v-if="item.type === 'note'" class="badge bg-success">노트</span>
                        <span v-if="item.type === 'selectQuiz'" class="badge bg-success"
                          >객관식 퀴즈</span
                        >
                      </td>
                      <td>{{ item.orderNum }}</td>
                      <td>
                        <span v-if="item.openYn === 'Y'" class="badge bg-primary">공개중</span>
                        <span v-if="item.openYn === 'N'" class="badge bg-info">비공개</span>
                      </td>
                      <td>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="me-1"
                          @click="$router.push(`/notice/modify/${item.idx}`)"
                          >수정</b-button
                        >
                        <b-button
                          variant="danger"
                          size="sm"
                          @click="deleteNotice(item.idx, item.type)"
                          >삭제</b-button
                        >
                      </td>
                    </tr>
                    <tr v-if="list.length === 0">
                      <td colspan="8">데이터가 없습니다.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="flex-jac">
                <b-pagination
                  v-model="pageData.page"
                  :total-rows="pageData.totalCnt"
                  :per-page="pageData.perPage"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Course',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'Notice List',
      items: [
        {
          text: 'Main',
          href: '/'
        },
        {
          text: 'Notice',
          href: '/'
        },
        {
          text: 'Notice List',
          active: true
        }
      ],
      courseId: '',
      sectionId: '',
      searchKeyword: '',
      courseList: [],
      sectionData: [],
      list: [],
      pageData: {
        page: 1, // 현재 페이지
        perPage: 15, // 페이지 초기 갯수
        totalCnt: 10000, // 전체 갯수
        pageStartIndex: 0 // 페이지 시작 번호
      }
    };
  },
  async created() {
    this.courseId = this.$store.state.post.courseId;
    this.sectionId = this.$store.state.post.sectionId;
    this.searchKeyword = this.$store.state.post.searchKeyword;

    if (this.$store.state.post.page !== 1) this.pageData.page = this.$store.state.post.page;
    if (this.$store.state.post.courseId !== null) this.courseId = this.$store.state.post.courseId;
    if (this.$store.state.post.sectionId !== null)
      this.sectionId = this.$store.state.post.sectionId;

    await this.initCourseList();
    await this.getNoticeList();
  },
  watch: {
    async courseId() {
      await this.$store
        .dispatch('section/getSectionList', {
          courseIdx: this.courseId
        })
        .then(res => {
          console.log(res);
        });

      this.$store.commit('notice/SET_COURSE_ID', this.courseId);
    }
  },
  methods: {
    pageClick(button, page) {
      this.pageData.page = page;
      this.$store.commit('notice/SET_PAGE', this.pageData.page);
      this.getPostList();
    },
    async initCourseList() {
      await this.$store
        .dispatch('course/getCourseList', {
          page: this.pageData.page,
          perPage: 10,
          order: 'asc'
        })
        .then(res => {
          this.courseList = res.data.list;
        });
    },
    async getNoticeList() {
      await this.$store
        .dispatch('notice/getNoticeList', {
          page: this.pageData.page ? this.pageData.page : 1,
          limit: this.pageData.perPage,
          courseId: this.courseId,
          serachKeyword: this.searchKeyword,
          orderKey: 'sn_order_num',
          orderAction: 'desc'
        })
        .then(({data}) => {
          console.log(this.pageData.page);
          this.list = data.list;
          this.pageData.totalCnt = data.page.total;
          this.pageData.pageStartIndex =
            this.pageData.totalCnt - (this.pageData.page - 1) * this.pageData.perPage;
          console.log(this.pageData.page);
        });
    },
    getPostListSearch() {
      this.$store.commit('notice/SET_SEARCH_KEYWORD', this.searchKeyword);
      this.pageData.page = 1;
      this.getPostList();
    },
    async deleteNotice(idx) {
      this.$confirm({
        message: `공지사항을 삭제하시겠습니까?`,
        button: {
          no: '취소',
          yes: '삭제'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            // ... do something

            await this.$store
              .dispatch('notice/deleteNotice', {
                noticeId: idx
              })
              .then(res => {
                this.$confirm({
                  title: res.result ? 'Success' : 'Error',
                  message: res.message,
                  button: {
                    yes: '확인'
                  }
                });

                if (res.result) {
                  this.getNoticeList();
                }
              });
          }
        }
      });
    }
  }
};
</script>
<style scoped>
table th,
table td {
  text-align: center;
}
form input,
form select {
  min-width: 80px;
  margin-right: 10px;
}
form button {
  min-width: 50px;
}
</style>
